<template>
  <!--begin::ContactZone-->
  <div>
    <div class="block2 row">
      <div class="col-lg-12">
        <div class="h2-primary mb-9">
          {{ $t("LANDING.CONTACT.TITLE") }}
          <div class="tertiary-rectangle" style="margin: 0.75em auto;"></div>
        </div>

        <div class="d-flex justify-content-around">
          <div>
            <inline-svg
              src="/media/landing/contact/telefono.svg"
              class="block2-icon"
            />
            <p class="mt-3">
              <strong>{{ $t("LANDING.CONTACT.CALL") }}</strong>
            </p>
            <p>
              +34 687 896 342
            </p>
          </div>
          <div>
            <inline-svg
              src="/media/landing/contact/correo.svg"
              class="block2-icon"
            />
            <p class="mt-3">
              <strong>{{ $t("LANDING.CONTACT.WRITE") }}</strong>
            </p>
            <p>
              <a href="mailto:info@logiok.es">info@logiok.es</a>
            </p>
          </div>
          <div>
            <inline-svg
              src="/media/landing/contact/mapa.svg"
              class="block2-icon"
            />
            <p class="mt-3">
              <strong>{{ $t("LANDING.CONTACT.WHERE") }}</strong>
            </p>
            <p>
              Rambla Catalunya 124
              <br />
              Planta 3, Puerta 1
              <br />
              Barcelona 08008
            </p>
          </div>
          <div>
            <inline-svg
              src="/media/landing/contact/reloj.svg"
              class="block2-icon"
            />
            <p class="mt-3">
              <strong>{{ $t("LANDING.CONTACT.WHEN") }}</strong>
            </p>
            <p>
              {{ $t("LANDING.CONTACT.DAYS") }}
              <br />
              09:00 - 18:00
            </p>
          </div>
        </div>
      </div>
    </div>

    <LandingContactForm />
  </div>
  <!--end::ContactZone-->
</template>

<script>
import LandingContactForm from "@/view/pages/landing/ContactForm.vue";

export default {
  name: "LandingContactZone",

  components: {
    LandingContactForm
  }
};
</script>
